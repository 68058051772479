import React from "react";
import "./Thumbnail.css";

import Backend from "../../services/Backend";

const emptyRect = {
    width: 0,
    height: 0,
    top: 0,
    bottom: 0
};

class Thumbnail extends React.Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.maskSize = {
            width: 128,
            height: 40
        };

        if (this.props.maskSize) this.maskSize = { ...props.maskSize };

        this.state = {
            ...props,

            imageInfo: null,
            imgStyle: {}
        };
    }
    componentDidMount() {
        if (!this.state.imageKey) return;
        Backend.ApiGetImageInfo(this.state.imageKey).then((data) => {
            this.setState({ imageInfo: data });
        });
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const imageKey = this.props.imageKey;
        if (imageKey && imageKey !== prevProps.imageKey) {
            Backend.ApiGetImageInfo(imageKey).then((data) => {
                this.setState({ imageKey: imageKey, imageInfo: data });
            });
        }
    }

    calcMaskRect = (orig_size, crop_rect, imgEl) => {
        // crop_tect - original crop from source image before prediction, it's a perception zone
        // imgEl - html img element containing a frame preview
        let left = (crop_rect[0] * imgEl.width) / orig_size[0];
        let right = (crop_rect[2] * imgEl.width) / orig_size[0];
        let top = (crop_rect[1] * imgEl.height) / orig_size[1];
        let bottom = (crop_rect[3] * imgEl.height) / orig_size[1];

        //console.log("mask_crop", mask_crop, "crop_rect", crop_rect);
        //console.log("natural", imgEl.naturalWidth, imgEl.naturalHeight);
        //console.log("element", imgEl.width, imgEl.height);

        return [left, top, right, bottom];
    };

    handleImgLoad = () => {
        const imgInfo = this.state.imageInfo;
        if (!imgInfo) {
            console.error("Image info == null, skipping mask update");
            this.setState({ maskRect: emptyRect });
            return;
        }

        const el = this.imgRef.current;

        let [cropLeft, cropTop, cropRight, cropBottom] = imgInfo.crop_rect;

        cropLeft /= imgInfo.orig_size[0];
        cropRight /= imgInfo.orig_size[0];
        cropTop /= imgInfo.orig_size[1];
        cropBottom /= imgInfo.orig_size[1];

        cropLeft *= el.width;
        cropRight *= el.width;
        cropTop *= el.height;
        cropBottom *= el.height;

        const cropWidth = cropRight - cropLeft;
        const cropHeight = cropBottom - cropTop;

        const scaleX = this.maskSize.width / cropWidth;
        const scaleY = this.maskSize.height / cropHeight;

        const newWidth = el.width * scaleX;
        const newHeight = el.height * scaleY;
        const newLeft = -cropLeft * scaleX;
        const newTop = -cropTop * scaleY;

        const stateUpdate = {
            imgStyle: {
                width: newWidth,
                height: newHeight,
                left: newLeft,
                top: newTop
            }
        };
        //console.log("Update img position", stateUpdate.imgStyle);

        this.setState(stateUpdate);
    };

    handleClicked = () => {
        console.log("handle clicked");
    };

    render() {
        if (!this.state.imageKey) return null;

        const mediaWidth = this.maskSize.width > 200 ? 1024 : 320;
        const picUrl = `https://storage.googleapis.com/kenmore/${this.state.imageKey}.jpg`;
        const letter = this.state.imageKey[0].toUpperCase();
        const img = !this.state.imageInfo ? null : (
            <img
                alt='road'
                src={picUrl}
                ref={this.imgRef}
                onLoad={() => this.handleImgLoad()}
                onClick={() => this.props.onClick(this.state.imageKey)}
                style={this.state.imgStyle}
            />
        );

        const mask = !this.props.showMask ? null : (
            <div
                className='ThumbnailMask'
                style={{
                    backgroundImage: `url(release/masks/${letter}/${this.state.imageKey}.png)`,
                    ...this.maskSize
                }}
            />
        );

        return (
            <div className='Thumbnail' style={this.maskSize}>
                {img}
                {mask}
            </div>
        );
    }
}

export default Thumbnail;
