import React from "react";
import classes from "./Streets.module.css";
import common from "../../Common.module.css";

const epmtyObject = {};

const Street = (props) => {
    const divClasses = classes.Street + (props.selected ? " " + classes.Selection : "");
    const spanStyle = props.selected ? { fontWeight: "600" } : epmtyObject;
    return (
        <div
            className={divClasses}
            onClick={() => props.onClick(props.city, props.road, props.index)}
        >
            <div className={classes.Road}>
                <span style={spanStyle}>{props.road}</span>
            </div>
            <div className={classes.City}>
                <span style={spanStyle}>{props.city}</span>
            </div>
        </div>
    );
};

const Streets = (props) => {
    if (!props.streets) return <div className={classes.Streets}>Connecting..</div>;

    return (
        <div className={classes.Streets}>
            {" "}
            <div className={common.Caption}>Street/City</div>
            <div className={classes.StreetContainer}>
                {props.streets.map((s, i) => (
                    <Street
                        key={i}
                        index={i}
                        city={s[0]}
                        road={s[1]}
                        onClick={props.onClick}
                        selected={i === props.selectedStreetIndex}
                    />
                ))}
            </div>
        </div>
    );
};
export default Streets;
