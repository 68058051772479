class Settings {
    MAPSTYLE = "mapbox://styles/vladimir78/cjo9spgu60o8w2rs0o79tqp7k";

    ROAD_COLORING = [
        0.2,
        "hsl(0, 100%, 63%)",
        0.7,
        "hsl(0, 100%, 50%)",
        0.85,
        "hsl(26, 100%, 52%)",
        0.95,
        "hsl(59, 100%, 63%)",
        1.0,
        "hsl(128, 97%, 56%)"
    ];

    LINE_COLOR_PROPERTY = ["interpolate", ["linear"], ["get", "quality"]].concat(
        this.ROAD_COLORING
    );

    RELEASE_URL = "release";
    DETAILED_URL = `${this.RELEASE_URL}/geo.json`;
    LOWRES_URL = `${this.RELEASE_URL}/bigmap.json`;

    BEST_COUNT = 5;

    LAYER_BOUNDARY = "city-boundary";
    LAYER_BLACKBG_DETAILED = "blackbg-detailed";
    LAYER_BLACKBG_LOWRES = "blackbg-bitmap";
}

class KenmoreSettings extends Settings {
    LAYER_DETAILED = "kenmore";
    LAYER_LOWRES = "kenmore-lowres";

    SOURCE_DETAILED = `${this.LAYER_DETAILED}-source`;
    SOURCE_LOWRES = `${this.LAYER_LOWRES}-source`;

    BOUNDARY_URL = `${this.RELEASE_URL}/area_border.json`;

    DEFAULT_LNG = -122.2397;
    DEFAULT_LAT = 47.7562;
    DEFAULT_ZOOM = 13.0;
    DEFAULT_IMGSTART = "R5an_Q7Py62Wag49d1WOIA";

    BOUNDS_LNG = [-124, -120.0]; // west est
    BOUNDS_LAT = [47, 49]; // south north
}
/*
class MinnesotaSettings extends Settings {
    
    MAPSTYLE = 'mapbox://styles/vladimir78/cju87a0qv05ak1fqq5knhsics';
    LAYER_DETAILED = "minnesota1";
    LAYER_LOWRES = "minnesota1-bigmap";

    SOURCE_DETAILED = `${this.LAYER_DETAILED}-source`;
    SOURCE_LOWRES = `${this.LAYER_LOWRES}-source`;

    DEFAULT_LNG = -92.957896;
    DEFAULT_LAT = 44.947721;
    DEFAULT_ZOOM = 13.15;
    DEFAULT_IMGSTART = '88w-bTVY7XPEWOVscNQKNg';

    BOUNDS_LNG = [-98, -88];      // west est
    BOUNDS_LAT = [43, 49];        // south north

}

class VermontSettings extends Settings {

    MAPSTYLE = 'mapbox://styles/vladimir78/cjo9spgu60o8w2rs0o79tqp7k';

    LAYER_DETAILED = "tilesetMarch1";
    LAYER_LOWRES = "bigmap-2ek8fg";

    SOURCE_DETAILED = `${this.LAYER_DETAILED}-source`;
    SOURCE_LOWRES = `${this.LAYER_LOWRES}-source`;

    DEFAULT_LNG = -72.47495;
    DEFAULT_LAT = 43.7669;
    DEFAULT_ZOOM = 13.2;
    DEFAULT_IMGSTART = '4JTNgyZ3XdLbRmDk4hm2ow';
    
    BOUNDS_LNG = [-75, -70];      // west est
    BOUNDS_LAT = [42, 45];        // south north
   
}
*/
export default KenmoreSettings;
