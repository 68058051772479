import React, { useRef, useState } from "react";
import "./ImagePreview.css";
import Mask from "../Mask/Mask";

const emptyState = {
    rect: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0
    },
    showMask: false
};

function relocate_mask(orig_size, crop_rect, imgEl) {
    // crop_tect - original crop from source image before prediction, it's a perception zone
    // imgEl - html img element containing a frame preview
    let left = (crop_rect[0] * imgEl.width) / orig_size[0];
    let right = (crop_rect[2] * imgEl.width) / orig_size[0];
    let top = (crop_rect[1] * imgEl.height) / orig_size[1];
    let bottom = (crop_rect[3] * imgEl.height) / orig_size[1];

    let mask_crop = [left, top, right, bottom];
    //console.log("mask_crop", mask_crop, "crop_rect", crop_rect);
    //console.log("natural", imgEl.naturalWidth, imgEl.naturalHeight);
    //console.log("element", imgEl.width, imgEl.height);

    return mask_crop;
}

function handleImgLoad(imgRef, imgInfo, setState) {
    if (!imgInfo) {
        console.error("Image info == null, skipping mask update");
        setState(...emptyState);
        return;
    }

    // update hovering mask
    let mask_crop = relocate_mask(imgInfo.orig_size, imgInfo.crop_rect, imgRef.current);
    //console.log(mask_crop);
    console.assert(mask_crop);

    setState({
        rect: {
            width: Math.round(mask_crop[2] - mask_crop[0] - 1),
            height: Math.round(mask_crop[3] - mask_crop[1] - 1),
            left: Math.round(mask_crop[0]),
            top: Math.round(mask_crop[1])
        },
        showMask: true
    });
}

const ImagePreview = (props) => {
    const imgRef = useRef(null);

    const [state, setState] = useState({...emptyState});

    const picUrl = `https://storage.googleapis.com/kenmore/${props.imageKey}.jpg`;
    const imgInfo = props.imageInfo;

    if (props.imageKey && imgInfo && imgInfo.img !== props.imageKey)
        console.error('Wrong imgid');

    const canvas = !props.imageKey
        ? null
        : [
              <img
                  className={"Image " + props.size}
                  src={picUrl}
                  alt='road'
                  ref={imgRef}
                  onLoad={!imgInfo ? null : () => handleImgLoad(imgRef, imgInfo, setState)}
                  onClick={() => props.onClick(props.imageKey)}
                  key='1'
              />,
              <Mask rect={state.rect} imageKey={props.imageKey} key='2' show={state.showMask} />
          ];

    return (
        <div className='ImagePreview'>
            <div className='ImagePlaceholder large'>{canvas}</div>
            {props.children}
        </div>
    );
};

export default ImagePreview;
