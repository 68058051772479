import React from "react";
import classes from "./LeftBar.module.css";
import Label from "../Label/Label";
import ImagePreview from "../ImagePreview/ImagePreview";
import SegmentPreview from "../SegmentPreview/SegmentPreview";
import Streets from "../Streets/Streets";

const LeftBar = (props) => {
    const safeInfo = props.imageInfo || {};
    const image = !props.imageInfo ? null : (
        <div>
            <ImagePreview
                imageKey={props.imageKey}
                imageInfo={props.imageInfo}
                size='large'
                onClick={(img) => props.onPreviewImageClick(img)}
            >
                <div className={classes.NextPrevButtons}>
                    <button className={classes.Prev} onClick={props.onPrevClicked}>
                        Previous
                    </button>
                    <button className={classes.Next} onClick={props.onNextClicked}>
                        Next
                    </button>
                </div>
            </ImagePreview>

            <div className={classes.LabelBlock}>
                <Label labelKey='Image' labelValue={props.imageKey} />
                <Label labelKey='Position' labelValue={props.cursor + "/" + props.total} />
                <Label labelKey='Sequence' labelValue={safeInfo.seq} />

                <Label labelKey='Latitude' labelValue={safeInfo.lat0} />
                <Label labelKey='Longitude' labelValue={safeInfo.lng0} />
                <Label labelKey='Quality' labelValue={safeInfo.quality.toFixed(2)} />
            </div>
        </div>
    );

    const segment = !props.histogram ? null : (
        <SegmentPreview
            worstN={props.worstN}
            bestN={props.bestN}
            Nconst={props.Nconst}
            histogram={props.histogram}
            onClick={(img) => props.onPreviewImageClick(img)}
        />
    );

    //console.log(safeInfo);
    return (
        <div className={classes.LeftBar}>
            {segment}

            {image}

            <Streets
                streets={props.streets}
                onClick={props.onStreetClick}
                selectedStreet={props.selectedStreet}
                selectedStreetIndex={props.selectedStreetIndex}
            />
        </div>
    );
};

export default LeftBar;
