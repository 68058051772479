import React from "react";
import common from "../../Common.module.css";
import classes from "./SegmentPreview.module.css";
import Thumbnail from "../Thumbnail/Thumbnail";

const Histogram = (props) => {
    let histArray = props.histogram;
    if (!histArray) return null;

    let skipInitialZeroes = true;
    let total = 0;

    histArray = histArray.filter((row) => {
        if (skipInitialZeroes)
            if (row[1]) {
                skipInitialZeroes = false;
                total += row[1];
                return true;
            } else return false;
        else {
            total += row[1];
            return true;
        }
    });

    const len = histArray.length;
    const rows = histArray.map((row, i, arr) => {
        const counter = row[1];
        const from = row[0];
        const till = i === len - 1 ? 100 : arr[i + 1][0];
        const percentage = (100 * counter) / total;

        return (
            <div className={classes.HistRow} key={i}>
                <div className={classes.HistCaption}>
                    <div className={classes.From}>{from}</div>
                    <div>..</div>
                    <div className={classes.Till}>{till}</div>
                </div>
                <div className={classes.HistZone}>
                    <div
                        className={classes.HistProgress}
                        style={{ width: percentage.toFixed(2) + "%" }}
                    />
                    <div className={classes.HistCounter}>{counter}</div>
                </div>
            </div>
        );
    });
    return <div className={classes.HistogramContainer}>{rows}</div>;
};

const SegmentPreview = (props) => {
    const worst = !props.worstN
        ? null
        : props.worstN.map((one, i) => (
              <Thumbnail key={i} imageKey={one.img} onClick={props.onClick} showMask/>
          ));
    const best = !props.bestN
        ? null
        : props.bestN.map((one, i) => (
              <Thumbnail key={i} imageKey={one.img} onClick={props.onClick} showMask/>
          ));

    if (!worst && !best) return null;

    return (
        <div className={classes.SegmentPreview}>
            <div>
                <div className={classes.column}>
                    <div className={common.Caption}>{props.Nconst} most damaged</div>
                    <div className={[classes.Worst, classes.Nseq].join(" ")}>{worst}</div>
                </div>
                <div className={classes.column}>
                    <div className={common.Caption}>{props.Nconst} best fragments</div>
                    <div className={[classes.Best, classes.Nseq].join(" ")}>{best}</div>
                </div>
            </div>
            <div className={common.Caption}>Histogram</div>
            <Histogram histogram={props.histogram} />
        </div>
    );
};

export default SegmentPreview;
