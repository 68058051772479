import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "./MapArea.css";
import axios from "axios";

class MapArea extends Component {
    ROAD_COLORING = [
        0.3,
        "hsl(0, 100%, 63%)",
        0.4,
        "hsl(0, 100%, 50%)",
        0.6,
        "hsl(26, 100%, 52%)",
        0.8,
        "hsl(59, 100%, 63%)",
        1.0,
        "hsl(128, 97%, 56%)"
    ];

    constructor(props) {
        super(props);

        this.settings = props.settings;
        this.state = { cameraPoint: [-122.2601718, 47.7615237] };
        this.map = null;
        this.fullMapLayers = [];
    }

    componentDidMount = () => {
        const settings = this.settings;
        const searchParams = new URLSearchParams(this.props.location.search);
        const newState = {
            LNG: searchParams.get("g") || settings.DEFAULT_LNG,
            LAT: searchParams.get("t") || settings.DEFAULT_LAT,
            ZOOM: searchParams.get("z") || settings.DEFAULT_ZOOM,
            IMGSTART: searchParams.get("i") || settings.DEFAULT_IMGSTART
        };
        Object.assign(this.state, newState);

        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: settings.MAPSTYLE,
            center: [newState.LNG, newState.LAT],
            zoom: newState.ZOOM,
            maxBounds: new mapboxgl.LngLatBounds(
                // ws en
                [settings.BOUNDS_LNG[0], settings.BOUNDS_LAT[0]],
                [settings.BOUNDS_LNG[1], settings.BOUNDS_LAT[1]]
            ),
            maxZoom: 17,
            minZoom: 12
        });

        this.map.on("load", () => {
            this.handleMapLoad();
        });
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.selectedStreet !== this.props.selectedStreet) {
            this.handleFilterChange(this.props.selectedStreet);
        }
        if (prevProps.showFullMap !== this.props.showFullMap) {
            this.handleShowFullMapChange(this.props.showFullMap);
        }
        if (prevProps.cameraPoint !== this.props.cameraPoint) {
            this.relocateCamera(this.props.cameraPoint);
        }
        // if (prevProps.location !== this.props.location) {
        //     this.setState ???
        // }
    };

    relocateCamera = (pt) => {
        console.log(pt);
        //map.flyTo({center: middle});
        this.map.getSource("camera").setData(this.makePoint(pt));
        this.map.panTo(pt);
    };

    mainLayerJson = (id, source) => {
        const settings = this.settings;
        return {
            id: id,
            type: "line",
            source: source,
            layout: {
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-color": settings.LINE_COLOR_PROPERTY,
                "line-width": ["interpolate", ["linear"], ["zoom"], 0, 2, 5, 4, 16, 8, 20, 12]
            },
            minzoom: 14,
            maxzoom: 24
        };
    };

    expandWidths = (widthDescription) => {
        console.log(widthDescription);
        for (let i = 4; i < widthDescription.length; i += 2)
            widthDescription[i] = widthDescription[i] + 2;
        console.log(widthDescription);
    };

    generateBlackLayer = (optionsIn, id) => {
        let options = JSON.parse(JSON.stringify(optionsIn));
        options.id = id;
        options.paint["line-color"] = "#000";
        this.expandWidths(options.paint["line-width"]);
        return options;
    };

    getBounds = (geojson) => {
        const features = geojson.features;
        const first = features[0].geometry.coordinates;
        const bounds = features.reduce((bounds, feature) => {
            const pt = feature.geometry.coordinates;
            bounds.extend(pt[0]);
            bounds.extend(pt[1]);
            return bounds;
        }, new mapboxgl.LngLatBounds(first[0], first[1]));

        return bounds;
    };

    handleFilterChange = (selectedStreetUrl) => {
        if (!selectedStreetUrl) return;

        axios.get(selectedStreetUrl).then((response) => {
            const geojson = response.data;
            this.showAnotherStreet(geojson);
        });
    };

    showAnotherStreet = (geojson) => {
        const LAYER_STREET = "street";
        const LAYER_BLACKBG_STREET = "blackbg-street";
        const SOURCE_STREET = "street-source";

        const map = this.map;
        if (map.getLayer(LAYER_STREET)) map.removeLayer(LAYER_STREET);
        if (map.getLayer(LAYER_BLACKBG_STREET)) map.removeLayer(LAYER_BLACKBG_STREET);
        if (map.getSource(SOURCE_STREET)) map.removeSource(SOURCE_STREET);

        const bounds = this.getBounds(geojson);
        this.map.fitBounds(bounds, { padding: 50 });

        map.addSource(SOURCE_STREET, {
            type: "geojson",
            //            data: selectedStreetUrl
            data: geojson
        });

        const desc = this.mainLayerJson(LAYER_STREET, SOURCE_STREET);
        desc.minzoom = 0;
        map.addLayer(this.generateBlackLayer(desc, LAYER_BLACKBG_STREET), 'camera');
        map.addLayer(desc, 'camera');

        this.setLayerCursor(LAYER_STREET);
        map.on("click", LAYER_STREET, this.handleDetailedClick.bind(this));
    };

    handleDetailedClick = (e) => {
        const imageKey = e.features[0].properties["image-key"];
        this.props.detailedLineClicked(imageKey);
    };

    handleShowFullMapChange = (showFullMap) => {
        const show = showFullMap ? "visible" : "none";
        this.fullMapLayers.forEach((layer) => {
            this.map.setLayoutProperty(layer, "visibility", show);
        });
    };

    select = () => {};
    segment_report = () => {};

    updateUrl = () => {
        const history = this.props.history;
        const imageKey = this.props.imageKey;
        const searchStr =
            `?g=${this.state.LNG.toFixed(7)}` +
            `&t=${this.state.LAT.toFixed(7)}` +
            `&z=${this.state.ZOOM.toFixed(2)}` +
            (imageKey ? `&i=${imageKey}` : ``);

        const prevImage = new URLSearchParams(history.location.search).get("i");
        const replace = prevImage !== imageKey;
        if (replace) {
            history.replace({ search: searchStr });
        } else {
            history.push({ search: searchStr });
        }
    };

    handleMapLoad = () => {
        const map = this.map;
        const settings = this.settings;
        map.addSource(settings.SOURCE_DETAILED, {
            type: "geojson",
            data: settings.DETAILED_URL
        });

        map.addSource(settings.SOURCE_LOWRES, {
            type: "geojson",
            data: settings.LOWRES_URL
        });

        const SOURCE_BOUNDARY = "boundary-source";

        if (settings.hasOwnProperty("BOUNDARY_URL")) {
            map.addSource(SOURCE_BOUNDARY, {
                type: "geojson",
                data: settings.BOUNDARY_URL
            });
            map.addLayer({
                id: settings.LAYER_BOUNDARY,
                type: "line",
                source: SOURCE_BOUNDARY,
                layout: {
                    "line-cap": "round",
                    "line-join": "round"
                },

                paint: {
                    "line-color": "hsl(300,100%,10%)",
                    "line-width": 2,
                    "line-blur": 2,
                    "line-dasharray": [6, 4]
                }
            });
        }

        const detailedDesc = this.mainLayerJson(settings.LAYER_DETAILED, settings.SOURCE_DETAILED);
        map.addLayer(this.generateBlackLayer(detailedDesc, settings.LAYER_BLACKBG_DETAILED));
        map.addLayer(detailedDesc);

        const lowresDesc = {
            id: settings.LAYER_LOWRES,
            type: "line",
            source: settings.SOURCE_LOWRES,
            layout: {
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-color": settings.LINE_COLOR_PROPERTY,
                "line-width": ["interpolate", ["linear"], ["zoom"], 0, 1, 8, 3, 12, 5]
            },
            minzoom: 0,
            maxzoom: 14
        };
        const lowresbgDesc = this.generateBlackLayer(lowresDesc, settings.LAYER_BLACKBG_LOWRES);

        map.addLayer(lowresbgDesc);
        map.addLayer(lowresDesc);

        this.fullMapLayers = [
            settings.LAYER_BOUNDARY,
            settings.LAYER_BLACKBG_DETAILED,
            settings.LAYER_DETAILED,
            settings.LAYER_BLACKBG_LOWRES,
            settings.LAYER_LOWRES
        ];

        this.subscribeEvents(map);

        map.addSource("camera", {
            type: "geojson",
            data: this.makePoint([this.props.cameraPoint])
        }); // lng lat
        this.loadMarkerAsset();

        // TODO: set initial image
        /*
    
        if (IMGSTART)
            setTimeout(()=>{select(IMGSTART)}, 500);
    */
    };

    makePoint = (pos /* [lng,lat] */) => {
        return { type: "Point", coordinates: pos };
    };

    middlePoint = (c) => {
        // coordinates
        return [(c[0][0] + c[1][0]) / 2, (c[0][1] + c[1][1]) / 2];
    };

    loadMarkerAsset() {
        const map = this.map;
        map.loadImage("assets/marker.png", function(error, image) {
            if (error) throw error;
            console.log("Loaded image", image);
            map.addImage("custom-marker", image);
            /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
            map.addLayer({
                id: "camera",
                source: "camera",
                type: "symbol",
                layout: {
                    "icon-image": "custom-marker",
                    "icon-offset": [0, -31]
                }
            });
        });
    }

    setLayerCursor = (layer) => {
        // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
        //, settings.LAYER_LOWRES]
        this.map.on("mouseenter", layer, () => (this.map.getCanvas().style.cursor = "pointer"));
        this.map.on("mouseleave", layer, () => (this.map.getCanvas().style.cursor = ""));
    };
    subscribeEvents = (map) => {
        const settings = this.settings;

        var nav = new mapboxgl.NavigationControl();
        map.addControl(nav, "top-right");

        this.setLayerCursor(settings.LAYER_DETAILED);
        this.setLayerCursor(settings.LAYER_LOWRES);

        map.on("click", settings.LAYER_DETAILED, this.handleDetailedClick.bind(this));

        map.on("click", settings.LAYER_LOWRES, (e) => {
            this.props.segmentClicked(e.features[0].properties);
        });

        map.on("move", () => {
            this.setState({
                LNG: map.getCenter().lng,
                LAT: map.getCenter().lat,
                ZOOM: map.getZoom()
            });
        });
        map.on("moveend", () => {
            this.updateUrl();
        });
    };

    render() {
        return <div className='MapArea' ref={(el) => (this.mapContainer = el)}></div>;
    }
}

export default withRouter(MapArea);
