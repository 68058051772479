import React from "react";
import "./Mask.css";

const Mask = (props) => {
    const letter = props.imageKey[0].toUpperCase();
    const styles = {
        backgroundImage: `url(release/masks/${letter}/${props.imageKey}.png)`,
        ...props.rect
    };

    return <div className='Mask' style={styles} />;
};

export default Mask;
