import React, { useState, useEffect } from "react";

import Modal from "../../ui/Modal/Modal.js";
import Thumbnail from "../Thumbnail/Thumbnail.js";

const ModalImagePreview = (props) => {
    const [state, setState] = useState({ showMask: true });

    const toggleChange = () => {
        setState({ showMask: !state.showMask });
    };

    useEffect(() => {
        if (!props.show) setState({ showMask: true });
    }, [props.show]);

    return (
        <Modal show={props.show} onModalClosed={props.onModalClosed}>
            <label>
                <input type='checkbox' checked={state.showMask} onChange={toggleChange} />
                Show mask
            </label>

            <Thumbnail
                imageKey={props.imageKey}
                maskSize={{ width: 1024, height: 320 }}
                onClick={() => 1}
                showMask={state.showMask}
            />
        </Modal>
    );
};

export default ModalImagePreview;
