import React, { Component } from "react";
import "./App.css";

import SettingsClass from "./settings/Settings";
import LeftBar from "./components/LeftBar/LeftBar";
import MapArea from "./components/MapArea/MapArea";
import Backend from "./services/Backend";
import ModalImagePreview from "./components/ModalImagePreview/ModalImagePreview";
import { BrowserRouter } from "react-router-dom";

const settings = new SettingsClass();

class App extends Component {
    initialState = {
        imageKey: null,
        imageInfo: null,
        sequenceKey: null,
        imagesList: null,
        cursor: null,
        worstN: null,
        bestN: null,
        lower: null,
        upper: null,
        histogram: null,
        showingPhotoModal: null,
        streets: [],
        selectedStreet: null,
        selectedStreetIndex: -1,
        showFullMap: true,
        cameraPoint: [-122.2601718, 47.7615237]
    };
    state = { ...this.initialState };

    componentDidMount = async () => {
        const streets = await Backend.ApiGetStreets();
        this.setState({ streets: streets });
        this.initialState.streets = streets; // remember it forever, won't be reread
    };

    handleDetailedLineClick = async (imageKey, cursor = null) => {
        this.setState({ imageKey: null, sequenceKey: null, histogram: null });
        const imageInfo = await Backend.ApiGetImageInfo(imageKey);
        const imagesList = await Backend.ApiGetSequenceImageList(imageInfo.seq);

        this.setState({
            cursor: cursor || imagesList.findIndex((a) => a === imageKey),
            imageKey: imageInfo.img,
            imageInfo: imageInfo,
            imagesList: imagesList,
            cameraPoint: [imageInfo.lng0, imageInfo.lat0]
        });
    };

    handleSegmentClick = async (segment) => {
        console.log(segment);
        const range = JSON.parse(segment.range);
        const sequence = segment.sequence;
        console.log(sequence, range);
        const [lower, upper] = range;

        this.setState({ sequenceKey: sequence, lower: lower, upper: upper, ...this.initialState });

        const bestN = await Backend.ApiGetBestN(sequence, settings.BEST_COUNT, lower, upper);
        const worstN = await Backend.ApiGetWorstN(sequence, settings.BEST_COUNT, lower, upper);
        const histogram = await Backend.ApiGetHistogram(sequence, lower, upper);
        // const [imgList, bestN, worstN] = await Promise.all([
        //     b.getSequenceImageList(sequence),
        //     b.__ApiGetBestN(sequence, settings.BEST_COUNT, lower, upper),
        //     b.__ApiGetWorstN(sequence, settings.BEST_COUNT, lower, upper)
        // ]);

        this.setState({ bestN: bestN, worstN: worstN, histogram: histogram });
    };

    handlePreviewImageClick = (img) => {
        console.log(`Image ${img} clicked`);
        this.setState({ showingPhotoModal: img });
    };

    handleShowingPhotoExit = () => {
        this.setState({ showingPhotoModal: null });
    };

    handlePrevNextClicked = (step) => {
        let cursor = this.state.cursor;
        if (cursor == null) return;
        cursor = Math.max(Math.min(cursor + step, this.state.imagesList.length), 0);
        const imageKey = this.state.imagesList[cursor];
        this.handleDetailedLineClick(imageKey, cursor);
    };

    handleStreetClicked = (city, road, index) => {
        const url = encodeURI(`/data/street/filter/${city}/${road}`);
        console.log(url, index);
        this.setState({ selectedStreet: url, selectedStreetIndex: index, showFullMap: false });
    };

    render() {
        const s = this.state;
        return (
            <BrowserRouter>
                <div className='App'>
                    <ModalImagePreview
                        show={!!s.showingPhotoModal}
                        onModalClosed={this.handleShowingPhotoExit}
                        imageKey={s.showingPhotoModal}
                    />

                    <div className='Layout'>
                        <LeftBar
                            imageKey={s.imageKey}
                            imageInfo={s.imageInfo}
                            worstN={s.worstN}
                            bestN={s.bestN}
                            Nconst={settings.BEST_COUNT}
                            histogram={s.histogram}
                            cursor={s.cursor}
                            total={s.imagesList ? s.imagesList.length : 0}
                            onPreviewImageClick={(img) => this.handlePreviewImageClick(img)}
                            onPrevClicked={() => this.handlePrevNextClicked(-1)}
                            onNextClicked={() => this.handlePrevNextClicked(1)}
                            streets={s.streets}
                            onStreetClick={this.handleStreetClicked.bind(this)}
                            selectedStreetIndex={s.selectedStreetIndex}
                        />

                        <MapArea
                            settings={settings}
                            detailedLineClicked={this.handleDetailedLineClick}
                            segmentClicked={this.handleSegmentClick}
                            selectedStreet={s.selectedStreet}
                            showFullMap={s.showFullMap}
                            cameraPoint={s.cameraPoint}
                            imageKey={s.imageKey}
                        />
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
