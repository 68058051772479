import axios from "axios";

class BackendClass {
    constructor(API_URL = "./data") {
        this.API_URL = API_URL;
    }

    ApiGetStreets = async () => {
        let data = null;
        await axios.get(this.API_URL + "/streets")
            .then((resp) => {
                data = resp.data;
            })
            .catch((err) => console.error(err));
        return data;
    };

    ApiGetImageInfo = async (img) => {
        console.assert(img, "Image key parameter expected");
        const resp = await axios.get(this.API_URL + "/image/" + img);
        return resp.data;
    };
    ApiGetSequenceImageList = async (seq) => {
        console.log(this.API_URL + "/sequence/" + seq);
        const resp = await axios.get(this.API_URL + "/sequence/" + seq);
        return resp.data;
    };
    ApiGetBestN = async (seq, n, lower, upper) => {
        const resp = await axios.get(this.API_URL + `/bestN/${seq}/${n}/${lower}/${upper}`);
        return resp.data;
    };
    ApiGetWorstN = async (seq, n, lower, upper) => {
        const resp = await axios.get(this.API_URL + `/worstN/${seq}/${n}/${lower}/${upper}`);
        return resp.data;
    };

    ApiGetHistogram = async (seq, lower, upper) => {
        const resp = await axios.get(this.API_URL + `/histogram/${seq}/${lower}/${upper}`);
        return resp.data;
    };

    // getImageInfo = async (imageKey) => {
    //     console.assert(imageKey);
    //     const cached = this.IMG_CACHE[imageKey];
    //     if (cached) {
    //         //console.log(`Image ${imageKey} has been read from cache`);
    //         return cached;
    //     }
    //     const resp = await this.__ApiGetImageInfo(imageKey);
    //     this.IMG_CACHE[imageKey] = resp.data;
    //     return resp.data;
    // };

    // getSequenceImageList = async (seq) => {
    //     let cachedList = this.SEQ_CACHE[seq];

    //     if (cachedList) {
    //         //console.log(`Sequence ${seq} has been read from cache`);
    //         return cachedList;
    //     }
    //     const resp = await this.__ApiGetSequenceInfo(seq);
    //     return (this.SEQ_CACHE[seq] = resp.data);
    // };
}

const Backend = new BackendClass();
export default Backend;
