import React from "react";
import "./Label.css";

const Label = (props) => {
    return (
        <div className="LabelWrapper">
            <div className="LabelKey">{props.labelKey}</div>
            <div className="LabelValue">{props.labelValue}</div>
        </div>
    );
};

export default Label;
